<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0 h2back">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span> Editar zona
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editLocation">
        <b-form class="mt-2" @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">

          <b-row>
            <b-col sm="6" class="body-info">
              <validation-provider #default="{ errors }" :name="'Nombre'" rules="required">
                <b-form-group :label="'Nombre'" label-for="location-name">
                  <b-form-input v-model="location.name" name="name" :placeholder="'Nombre'" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6" class="body-info">
              <b-form-group :label="'Descripción'" label-for="location-description">
                <quill-editor v-model="location.description" />
              </b-form-group>

              <div class="text-right">
                <b-button type="submit" variant="primary" class="mt-2 mr-1 text-uppercase">
                  Guardar
                </b-button>
              </div>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormCheckbox, BInputGroup,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import { config } from '@/shared/app.config'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    vSelect,
    ImageDropzone,
    BFormCheckbox,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      statusProjectsText: config.statusProjectsText,
      name: '',
      files: [],
      required,
      email,
      projectPrepare: false,
      items: [],
    }
  },
  computed: {
    ...mapGetters({
      location: 'locations/getLocation',
      // selectStatus: 'projects/getSelectStatus',
      // selectDepartments: 'departments/getSelectDepartments',
      // selectClients: 'clients/getSelectClients',
      // role: 'auth/getRole',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'locations/edit',
      getLocation: 'locations/getLocation',
      // getSelectStatus: 'projects/selectStatus',
      // getSelectDepartments: 'departments/selectDepartments',
      // getSelectClients: 'clients/selectClients',
    }),

    handleSubmit() {
      this.$refs.editLocation.validate().then(success => {
        if (success) {
          const { id } = this.location
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, location: formData })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
      })
    },
    setData() {

      this.projectPrepare = true
    },
    createFormData() {
      // const data = this.$refs.documents.getFormData('documents')
      const data = new FormData()
      data.append('name', this.location.name)
      data.append('description', this.location.description)

      return data
    },
  },
  async created() {
    await this.getLocation(this.$route.params.id)
    // await this.getSelectStatus()
    // await this.getSelectDepartments()
    // await this.getSelectClients()
    await this.setData()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

.icon-trash {
  padding-top: 7px;
}
</style>
